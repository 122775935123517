import "./Footer.scss";
import FooterBottom from "./FooterBottom";
import logo from "../../assets/travelMitraLogo.svg";

const Footer = (props) => {
  const pathName = window.location.pathname;
  const getFooterPara = () => {
    if (pathName !== "/persistent" && pathName !== "/xoriant") {
      return (
        <div className="footer-para">
          <p>
            We believe that everyone should be able to travel to their dream
            international destination, comfortably visit places of their choice
            and get the ultimate travel experience, all by managing their
            existing budgets.
          </p>
          <p>
            We make this possible by understanding your budget, commuting &
            staying preferences and wish lists of places to explore. We thus
            build the custom itinerary perfectly suited for you to get the best
            personalized travel experience.
          </p>
        </div>
      );
    } else {
      return (
        <div className="footer-para">
          <p>
            We believe that every {capitalize(pathName?.replaceAll("/", ""))}{" "}
            employee should be able to travel to their dream international
            destination, comfortably visit places of their choice and get the
            ultimate travel experience, all by managing their existing budgets
          </p>
          <p>
            We make this possible by understanding your budget, commuting &
            staying preferences and wish lists of places to explore. We thus
            build the custom itinerary perfectly suited for you to get the best
            personalized travel experience.
          </p>
        </div>
      );
    }
  };

  /** function to capitalize first letter */
  const capitalize = (s) =>
    (s && String(s[0])?.toUpperCase() + String(s)?.slice(1)) || "";

  return (
    <>
      <footer id="footer-all-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-6 col-12">
              <div className="all-fott-cov">
                <div className="footer0logo">
                  {pathName !== "/persistent" && pathName !== "/xoriant" ? (
                    <a href="#">
                      <img src={logo} alt="img" width={"250px"} />
                    </a>
                  ) : (
                    <a href="#" style={{ color: "white" }}>
                      TripMitra – {capitalize(pathName?.replaceAll("/", ""))}
                    </a>
                  )}
                </div>
                {/* <div className="footer-para">
                  <p>
                    We believe that everyone should be able to travel to their
                    dream international destination, comfortably visit places of
                    their choice and get the ultimate travel experience, all by
                    managing their existing budgets.
                  </p>
                  <p>
                    We make this possible by understanding your budget,
                    commuting & staying preferences and wish lists of places to
                    explore. We thus build the custom itinerary perfectly suited
                    for you to get the best personalized travel experience.
                  </p>
                </div> */}
                {getFooterPara()}
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 col-12"></div>
            {/* <div className="col-lg-2 col-md-6 col-sm-6 col-6">
              <div className="quick-link">
                <div className="comm-foot-hed">
                  <h6>Quick Links</h6>
                </div>
                <div className="foot-list">
                  <ul className="p-lg-0">
                    <li><a href="about.html">About</a></li>
                    <li><a href="contact.html">Contact Us.</a></li>
                    <li><a href="faq.html">Terms & conditions</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-6">
              <div className="quick-link-2">
                <div className="foot-list-2">
                  <ul className="p-lg-0">
                    <li><a href="index.html">Home</a></li>
                    <li><a href="faq.html">Testimonials</a></li>
                    <li><a href="service.html">Service</a></li>
                    <li><a href="blog.html">News</a></li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="comm-foot-hed">
                <h6>Connect with us</h6>
              </div>
              <div className="footer-para foot-list">
                <p>
                  <i className="fa fa-phone me-2"> </i>
                  <span>9822452300</span>
                </p>
                <p>
                  <i className="fa fa-map-marker-alt me-2"> </i>
                  <span>
                    80, Sahawas Society, 7, Snehal Apartments, Karvenagar, Pune
                    411052
                  </span>
                </p>
                <p>
                  <i className="fa fa-envelope me-2"> </i>
                  <span>enquiry@trip-mitra.com</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <FooterBottom />
    </>
  );
};
export default Footer;
