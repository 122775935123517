import "./ForgotPassword.scss";
import { useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPasswordSlice } from "../../shared/redux/slices/authSlice";
import BlockUi from "react-block-ui";
import * as Yup from "yup";

const ForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  // Formik
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Please enter your valid Email Address")
        .email("Must be a valid email"),
    }),
    onSubmit: (values) => {
      forgotPassword(values);
    },
  });

  const forgotPassword = async (values) => {
    setLoading(true);
    const resp = dispatch(forgotPasswordSlice({ email: values.email }));
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        setLoading(false);
        if (resp) {
          history.push("/login");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setLoading(false);
      });
  };

  return (
    <div className="col-lg-6 offset-lg-2 pt-5 pb-5 mx-auto">
      <BlockUi blocking={loading}>
        <div className="common_author_boxed">
          <div className="common_author_heading">
            <h3>Forgot Password</h3>
          </div>
          <div className="common_author_form">
            <form
              action="#"
              id="main_author_form"
              onSubmit={formik.handleSubmit}
            >
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your email"
                  name="email"
                  onChange={(e) => {
                    formik.setFieldValue("email", e.target.value);
                  }}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="error-message float-start mb-3">
                    {formik.errors.email}
                  </div>
                )}
              </div>
              <div className="common_form_submit">
                <button className="btn btn-2 btn_theme btn_md">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </BlockUi>
    </div>
  );
};
export default ForgotPassword;
