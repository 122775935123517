import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import * as Yup from "yup";

import { listCities } from "../../shared/redux/slices/dashboardSlice";

import moment from "moment/moment";
import { useDispatch } from "react-redux";

// creating functional component ans getting props from app.js and destucturing them
const StepOne = ({ nextStep, setStepOneValues, stepOneValues }) => {
  //creating error state for validation
  const [error, setError] = useState(false);

  const defaultFromDate = new Date();
  defaultFromDate.setDate(defaultFromDate.getDate() + 7);

  // Formik
  const formik = useFormik({
    initialValues: {
      number_of_adults: stepOneValues?.number_of_adults || 0,
      number_of_kids: stepOneValues?.number_of_kids || 0,
      number_of_senior_citizens: stepOneValues?.number_of_senior_citizens || 0,
      meal_plan: stepOneValues?.meal_plan || "",
      coupon_code: stepOneValues?.coupon_code || "",
      from_date: stepOneValues?.from_date || defaultFromDate,
      to_date: stepOneValues?.to_date || defaultFromDate,
      traveling_with: stepOneValues?.traveling_with || "",
      any_special_request: stepOneValues?.any_special_request || "",
      total_no_of_days: stepOneValues?.total_no_of_days || 0,
      total_no_of_nights: stepOneValues?.total_no_of_nights || 0,
    },
    validationSchema: Yup.object().shape({
      number_of_adults: Yup.string().required(
        "Please enter the number of adults"
      ),
      number_of_kids: Yup.string().required("Please enter the number for kids"),
      // number_of_senior_citizens: Yup.string().required(
      //   "Please enter the number for senior citizens"
      // ),
      from_date: Yup.string().required("Please select the from date"),
      // traveling_with: Yup.object().required("Please select traveling with."),
      // meal_plan: Yup.string().required("Please select the meal plan."),
    }),
    onSubmit: (values) => {
      let validDestinationList = validateDestinations();
      // let validOccasionList = validateOccasions();
      if (validDestinationList) {
        let destinations = getDestinations();
        let occasions = getOccasions();
        setStepOneValues({
          ...values,
          destinations,
          occasions,
          destinationFields,
          occasionFields,
        });
        nextStep();
      }
    },
  });

  const dispatch = useDispatch();

  const destinationObj = {
    city: "",
    no_days: "",
    no_nights: "",
    accommodation_preference: "",
    sequence: 0,
    validCity: true,
    validNoDays: true,
    validAccmodationPreference: true,
  };

  const occasionObj = {
    occasion: "",
    date_of_occasion: new Date(defaultFromDate),
    validOccasion: true,
    validDateOfOccasion: true,
  };

  const [destinationFields, setDestinationFields] = useState([destinationObj]);
  const [occasionFields, setOccasionFields] = useState([occasionObj]);
  const [cityOptions, setCityOptions] = useState([]);
  const [validDestination, setValidDestination] = useState(false);
  const [validOccasion, setValidOccasion] = useState(false);
  const [vegPreference, setVegPreference] = useState(false);
  const [nonVegPreference, setNonVegPreference] = useState(false);

  const accommodationOptions = [
    { value: "5 STAR", label: "5 Star" },
    { label: "4 STAR", value: "4 Star" },
    { label: "3 STAR", value: "3 Star" },
  ];

  const occasionOptions = [
    { value: "Honeymoon", label: "Honeymoon" },
    { value: "Anniversary", label: "Wedding anniversary" },
    { value: "Birthday", label: "Birthday" },
    { value: "Others", label: "Others" },
  ];

  const travelingWithOptions = [
    { value: "Family", label: "Family" },
    { value: "Friends", label: "Friends" },
    { value: "FamilyWithFriend", label: "Friends And Family" },
  ];

  const setTotalNoOfDaysAndNights = () => {
    let numOfDays = 0;

    for (let index = 0; index < destinationFields.length; index++) {
      numOfDays =
        numOfDays +
        (destinationFields[index].no_days
          ? parseInt(destinationFields[index].no_days)
          : 0);
    }

    formik.setFieldValue("total_no_of_days", numOfDays);
    formik.setFieldValue("total_no_of_nights", numOfDays - 1);

    let toDate = new Date(formik.values.from_date);
    toDate.setDate(toDate.getDate() + numOfDays);
    formik.setFieldValue("to_date", toDate);
  };

  const validateDestinations = () => {
    let validDestination = true;
    for (let index = 0; index < destinationFields.length; index++) {
      let destination = destinationFields[index];
      if (!destination.city) {
        destination.validCity = false;
        validDestination = false;
      } else {
        destination.validCity = true;
      }

      if (!destination.no_days) {
        destination.validNoDays = false;
        validDestination = false;
      } else {
        destination.validNoDays = true;
      }

      if (!destination.accommodation_preference) {
        destination.validAccmodationPreference = false;
        validDestination = false;
      } else {
        destination.validAccmodationPreference = true;
      }
    }
    setDestinationFields([...destinationFields]);
    setValidDestination(validDestination);
    return validDestination;
  };

  const validateDestinationFields = (index) => {
    let validDestination = true;
    let destinationList = [...destinationFields];
    // for (let index = 0; index < destinationFields.length; index++) {
    let destination = destinationList[index];
    if (!destination.city) {
      destination.validCity = false;
      validDestination = false;
    } else {
      destination.validCity = true;
    }

    if (!destination.no_days) {
      destination.validNoDays = false;
      validDestination = false;
    } else {
      destination.validNoDays = true;
    }

    if (!destination.accommodation_preference) {
      destination.validAccmodationPreference = false;
      validDestination = false;
    } else {
      destination.validAccmodationPreference = true;
    }
    // }
    setDestinationFields([...destinationList]);
    setValidDestination(validDestination);
    return validDestination;
  };

  const addDestinations = () => {
    const validDestinationFlag = validateDestinations();
    if (validDestinationFlag) {
      setDestinationFields([...destinationFields, { ...destinationObj }]);
    }
  };

  const handleDestinationFields = (value, field, index) => {
    const data = [...destinationFields];
    data[index][field] = value;
    if (field === "no_days") {
      data[index]["no_nights"] = value ? value - 1 : 0;
      setTotalNoOfDaysAndNights();
    }
    setDestinationFields([...data]);
  };

  const validateOccasions = () => {
    let validateOccasion = true;
    for (let index = 0; index < occasionFields.length; index++) {
      let occasionObj = occasionFields[index];
      if (!occasionObj.occasion) {
        occasionObj.validOccasion = false;
        validateOccasion = false;
      } else {
        occasionObj.validOccasion = true;
      }

      if (!occasionObj.date_of_occasion) {
        occasionObj.validDateOfOccasion = false;
        validateOccasion = false;
      } else {
        occasionObj.validDateOfOccasion = true;
      }
    }
    setOccasionFields([...occasionFields]);
    setValidOccasion(validateOccasion);
    return validateOccasion;
  };

  const validateOccasionList = (index) => {
    let validateOccasion = true;
    let occasionList = [...occasionFields];
    let occasionObj = occasionList[index];
    if (!occasionObj.occasion) {
      occasionObj.validOccasion = false;
      validateOccasion = false;
    } else {
      occasionObj.validOccasion = true;
    }

    if (!occasionObj.date_of_occasion) {
      occasionObj.validDateOfOccasion = false;
      validateOccasion = false;
    } else {
      occasionObj.validDateOfOccasion = true;
    }

    setOccasionFields([...occasionList]);
    setValidOccasion(validateOccasion);
    return validateOccasion;
  };

  const addOccasion = () => {
    const validateOccasionFlag = validateOccasions();
    if (validateOccasionFlag) {
      setOccasionFields([...occasionFields, { ...occasionObj }]);
    }
  };

  const handleOccasionFields = (value, field, index) => {
    const data = [...occasionFields];
    data[index][field] = value;
    setOccasionFields([...data]);
  };

  const removeDestination = (index) => {
    const data = [...destinationFields];
    data.splice(index, 1);
    setDestinationFields([...data]);
  };

  const removeOccasion = (index) => {
    let data = [...occasionFields];
    data.splice(index, 1);
    setOccasionFields([...data]);
  };

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    nextStep();
  };

  const handleFromDateChange = (value) => {
    formik.setFieldValue("from_date", value);
    let toDate = new Date(value);
    toDate.setDate(toDate.getDate() + formik.values.total_no_of_days);
    formik.setFieldValue("to_date", toDate);
  };

  const handleToDateChange = (value) => {
    formik.setFieldValue("to_date", value);
  };

  const getCities = async () => {
    const resp = dispatch(listCities());
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          const cities = resp;
          if (cities?.length) {
            const updatedCities = cities.map((city) => {
              return { label: city.name, value: city.uuid, ...city };
            });
            setCityOptions(updatedCities);
          } else {
            setCityOptions([]);
          }
        } else {
          setCityOptions([]);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
      });
  };

  const getDestinations = () => {
    let destinations = [];
    if (destinationFields && destinationFields.length) {
      for (let index = 0; index < destinationFields.length; index++) {
        let destination = destinationFields[index];
        destinations.push({
          city: destination.city?.value,
          no_days: parseInt(destination?.no_days || 0),
          no_nights: parseInt(destination?.no_nights || 0),
          accommodation_preference: destination?.accommodation_preference
            ?.map((preference, index) => {
              return preference?.value;
            })
            ?.join(", "),
          sequence: index,
        });
      }
    }

    return destinations;
  };

  const getOccasions = () => {
    let occasions = [];
    if (occasionFields && occasionFields.length) {
      for (let index = 0; index < occasionFields.length; index++) {
        let occasion = occasionFields[index];
        occasions.push({
          occasion: occasion.occasion?.value,
          date_of_occasion: moment(occasion?.date_of_occasion).format(
            "YYYY-MM-DD"
          ),
        });
      }
    }

    return occasions;
  };

  useEffect(() => {
    if (vegPreference && nonVegPreference) {
      formik.setFieldValue("meal_plan", "BOTH");
    } else if (vegPreference) {
      formik.setFieldValue("meal_plan", "VEG");
    } else if (nonVegPreference) {
      formik.setFieldValue("meal_plan", "NON VEG");
    }
  }, [vegPreference, nonVegPreference]);

  useEffect(() => {
    getCities();
    // Setting destination fields
    if (stepOneValues?.destinationFields) {
      setDestinationFields(stepOneValues?.destinationFields);
    }
    // Setting occasion fields
    if (stepOneValues?.occasionFields) {
      setOccasionFields(stepOneValues?.occasionFields);
    }

    // Setting meal plan
    if (stepOneValues?.meal_plan === "BOTH") {
      setVegPreference(true);
      setNonVegPreference(true);
    } else if (stepOneValues?.meal_plan === "VEG") {
      setVegPreference(true);
    } else if (stepOneValues?.meal_plan === "NON VEG") {
      setNonVegPreference(true);
    }
  }, []);

  return (
    <div className="common_author_boxed step-form">
      <div className="common_author_heading">
        <h3 className="text-start">Destination Details</h3>
      </div>
      <form id="profile_form_area" onSubmit={formik.handleSubmit}>
        {/* <FormikProvider value={formik}>
          <FieldArray
            name="destinations"
            render={(arrayHelpers) => (
              <div>
                {formik.values.destinations.map((destination, index) => (
                  <div className="row" key={`destination-${index}`}>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label for="mail-address">Destination</label>
                        <Select
                          name={`destinations[${index}].city`}
                          className="enquiry-select"
                          options={cityOptions}
                          placeholder="Select Destination"
                          classNamePrefix="city-destination"
                          value={formik.values.destinations.city}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `destinations[${index}].city`,
                              value
                            )
                          }
                          onBlur={formik.handleBlur}
                        />

                        {formik.errors.destinations && (
                          <div className="error-message">
                            {formik.errors.destinations[index]?.city}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label for="no-of-days">Total No. of Days</label>
                        <input
                          type="text"
                          className="form-control"
                          id="no-of-days"
                          placeholder="No. of Days"
                          value={formik.values.destinations.no_days}
                          name={`destinations[${index}].no_days`}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `destinations[${index}].no_days`,
                              e.target.value
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.destinations &&
                          formik.errors.destinations[index]?.no_days && (
                            <div className="error-message">
                              {formik.errors.destinations[index]?.no_days}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label for="no-of-days">Total No. of Nights</label>
                        <input
                          type="text"
                          className="form-control"
                          id="no-of-nights"
                          placeholder="No. of Nights"
                          value={formik.values.destinations.no_nights}
                          name={`destinations[${index}].no_nights`}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `destinations[${index}].no_nights`,
                              e.target.value
                            )
                          }
                        />
                        {formik.errors.destinations &&
                          formik.errors.destinations[index]?.no_nights && (
                            <div className="error-message">
                              {formik.errors.destinations[index]?.no_nights}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label for="accommodation-pref">Hotel Category</label>
                        <Select
                          name={`destinations[${index}].accommodation_preference`}
                          className="enquiry-select"
                          options={accommodationOptions}
                          placeholder="Select Hotel Category"
                          isMulti
                          classNamePrefix="hotel-category"
                          value={
                            formik.values.destinations.accommodation_preference
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `destinations[${index}].accommodation_preference`,
                              value
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.destinations &&
                          formik.errors.destinations[index]
                            ?.accommodation_preference && (
                            <div className="error-message">
                              {
                                formik.errors.destinations[index]
                                  ?.accommodation_preference
                              }
                            </div>
                          )}
                      </div>
                    </div>
                    {formik.values.destinations.length > 1 && (
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label className="w-100" for="">
                            &nbsp;
                          </label>
                          <a
                            onClick={() =>
                              removeDestination(index, arrayHelpers)
                            }
                          >
                            <i className="fa fa-trash fa-2x mt-3" />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="row">
                  <div className="col-12 mb-5">
                    <a
                      className="btn-2"
                      onClick={() =>
                        formik.isValid ? arrayHelpers.push({}) : null
                      }
                    >
                      Add Destination
                    </a>
                  </div>
                </div>
              </div>
            )}
          /> */}
        {/* </FormikProvider> */}
        {destinationFields.map((destination, index) => {
          return (
            <div className="row" key={`destination-${index}`}>
              <div className="col-lg-4">
                <div className="form-group">
                  <label for="mail-address">
                    Destination<span className="error-message">*</span>
                  </label>
                  <Select
                    name={`destinations[${index}].city`}
                    className="enquiry-select"
                    options={cityOptions}
                    placeholder="Select Destination"
                    classNamePrefix="city-destination"
                    value={destination.city}
                    onChange={(value) =>
                      handleDestinationFields(value, "city", index)
                    }
                    // onBlur={() => validateDestinationFields(index)}
                  />

                  {!destination.validCity && (
                    <div className="error-message">
                      Please select the destination
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label for="no-of-days">
                    Total No. of Days<span className="error-message">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="no-of-days"
                    placeholder="No. of Days"
                    value={destination.no_days}
                    name={`destinations[${index}].no_days`}
                    onChange={(e) =>
                      handleDestinationFields(e.target.value, "no_days", index)
                    }
                    onBlur={() => validateDestinationFields(index)}
                  />
                  {!destination.validNoDays && (
                    <div className="error-message">
                      Please enter the number of days
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label for="no-of-days">Total No. of Nights</label>
                  <input
                    type="text"
                    className="form-control"
                    id="no-of-nights"
                    placeholder="No. of Nights"
                    value={destination.no_nights}
                    name={`destinations[${index}].no_nights`}
                    onChange={(e) =>
                      handleDestinationFields(
                        e.target.value,
                        "no_nights",
                        index
                      )
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label for="accommodation-pref">
                    Hotel Category<span className="error-message">*</span>
                  </label>
                  <Select
                    name={`destinations[${index}].accommodation_preference`}
                    className="enquiry-select"
                    options={accommodationOptions}
                    placeholder="Select Hotel Category"
                    isMulti
                    classNamePrefix="hotel-category"
                    value={destination.accommodation_preference}
                    onChange={(value) =>
                      handleDestinationFields(
                        value,
                        "accommodation_preference",
                        index
                      )
                    }
                    onBlur={() => validateDestinationFields(index)}
                  />
                  {!destination.validAccmodationPreference && (
                    <div className="error-message">
                      Please select the hotel category
                    </div>
                  )}
                </div>
              </div>
              {destinationFields.length > 1 && (
                <div className="col-lg-3">
                  <div className="form-group">
                    <label className="w-100" for="">
                      &nbsp;
                    </label>
                    <a onClick={() => removeDestination(index)}>
                      <i className="fa fa-trash fa-2x mt-3" />
                    </a>
                  </div>
                </div>
              )}
            </div>
          );
        })}
        <div className="row">
          <div className="col-12 mb-5">
            <a className="btn-2" onClick={addDestinations}>
              Add Destination
            </a>
          </div>
        </div>

        {/* {occasionFields.map((occasion, index) => {
          return (
            <div className="row" key={`occasion-${index}`}>
              <div className="col-lg-3">
                <div className="form-group">
                  <label for="f-name">
                    Occasion<span className="error-message">*</span>
                  </label>
                  <Select
                    className="enquiry-select"
                    options={occasionOptions}
                    placeholder="Select Occasion"
                    classNamePrefix="occasion-control"
                    value={occasion.occasion}
                    onChange={(value) =>
                      handleOccasionFields(value, "occasion", index)
                    }
                    onBlur={() => validateOccasionList(index)}
                  />
                  {!occasion.validOccasion && (
                    <div className="error-message">
                      Please select the occasion
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-3 mb-5">
                <div className="form-group">
                  <label for="to-dat">
                    Occasion Date<span className="error-message">*</span>
                  </label>
                  <div className="date-field">
                    <DatePicker
                      id="to-date"
                      className="form-control"
                      selected={occasion.date_of_occasion}
                      showIcon
                      onChange={(value) =>
                        handleOccasionFields(value, "date_of_occasion", index)
                      }
                      dateFormat="dd/MM/yyyy"
                      onBlur={() => validateOccasionList(index)}
                    />
                    <i className="fa fa-calendar" />
                  </div>
                  {!occasion.validDateOfOccasion && (
                    <div className="error-message">
                      Please select the date of occasion
                    </div>
                  )}
                </div>
              </div>

              {occasionFields.length > 1 && (
                <div className="col-lg-3">
                  <div className="form-group">
                    <label className="w-100" for="">
                      &nbsp;
                    </label>
                    <a onClick={() => removeOccasion(index)}>
                      <i className="fa fa-trash fa-2x mt-3" />
                    </a>
                  </div>
                </div>
              )}
            </div>
          );
        })} */}
        {/* <div className="row">
          <div className="col-12 mb-5">
            <a className="btn-2" onClick={addOccasion}>
              Add Occasion
            </a>
          </div>
        </div> */}

        <div className="row">
          <div className="col-lg-3">
            <div className="form-group">
              <label for="to-dat">
                From<span className="error-message">*</span>
              </label>
              <div className="date-field">
                <DatePicker
                  id="from-date"
                  className="form-control"
                  showIcon={true}
                  onChange={handleFromDateChange}
                  dateFormat="dd/MM/yyyy"
                  name="from_date"
                  selected={formik.values.from_date}
                  minDate={defaultFromDate}
                  onChangeRaw={(e) => {
                    formik.setFieldTouched("from_date", true, true);
                  }}
                  onBlur={formik.handleBlur}
                />
                <i className="fa fa-calendar" />
              </div>
              {formik.errors.from_date && (
                <div className="error-message">{formik.errors.from_date}</div>
              )}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label for="to-dat">
                To<span className="error-message">*</span>
              </label>
              <div className="date-field">
                <DatePicker
                  id="to-date"
                  className="form-control"
                  selected={formik.values.to_date}
                  showIcon
                  onChange={handleToDateChange}
                  dateFormat="dd/MM/yyyy"
                  disabled
                  name="to_date"
                  minDate={defaultFromDate}
                />
                <i className="fa fa-calendar" />
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label for="no-of-days">Total No. of Days</label>
              <input
                type="text"
                className="form-control"
                id="no-of-days"
                placeholder="No. of Days"
                value={formik.values.total_no_of_days}
                disabled
                name="total_no_of_days"
                onChange={(e) => {
                  formik.setFieldValue("total_no_of_days", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label for="no-of-days">Total No. of Nights</label>
              <input
                type="text"
                className="form-control"
                id="no-of-nights"
                placeholder="No. of Nights"
                value={formik.values.total_no_of_nights}
                disabled
                name="total_no_of_nights"
                onChange={(e) => {
                  formik.setFieldValue("total_no_of_nights", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="form-group">
              <label for="no-of-days">
                No. Of Adults<span className="error-message">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="no-of-nights"
                placeholder="No. Of Adults"
                name="number_of_adults"
                value={formik.values.number_of_adults}
                onChange={(e) => {
                  formik.setFieldValue("number_of_adults", e.target.value);
                }}
              />
              {formik.errors.number_of_adults && (
                <div className="error-message">
                  {formik.errors.number_of_adults}
                </div>
              )}
            </div>
          </div>

          <div className="col-lg-3">
            <div className="form-group">
              <label for="no-of-days">
                No. Of Kids Below 5<span className="error-message">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="no-of-nights"
                placeholder="No. Of Kids"
                value={formik.values.number_of_kids}
                name="number_of_kids"
                onChange={(e) => {
                  formik.setFieldValue("number_of_kids", e.target.value);
                }}
              />
              {formik.errors.number_of_kids && (
                <div className="error-message">
                  {formik.errors.number_of_kids}
                </div>
              )}
            </div>
          </div>

          {/* <div className="col-lg-3">
            <div className="form-group">
              <label for="no-of-days">
                No. Of Senior Citizens<span className="error-message">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="no-of-nights"
                placeholder="No. Of Senior Citizens"
                value={formik.values.number_of_senior_citizens}
                name="number_of_senior_citizens"
                onChange={(e) => {
                  formik.setFieldValue(
                    "number_of_senior_citizens",
                    e.target.value
                  );
                }}
              />
              {formik.errors.number_of_senior_citizens && (
                <div className="error-message">
                  {formik.errors.number_of_senior_citizens}
                </div>
              )}
            </div>
          </div> */}

          {/* <div className="col-lg-3">
            <div className="form-group">
              <label for="f-name">
                Traveling With<span className="error-message">*</span>
              </label>
              <Select
                className="d-control"
                options={travelingWithOptions}
                placeholder="Traveling With"
                name="traveling_with"
                value={formik.values.traveling_with}
                onChange={(value) => {
                  formik.setFieldValue("traveling_with", value);
                }}
              />
              {formik.errors.traveling_with && (
                <div className="error-message">
                  {formik.errors.traveling_with}
                </div>
              )}
            </div>
          </div> */}

          <div className="col-lg-3">
            <div className="form-group">
              <label for="no-of-days">Coupon Code</label>
              <input
                type="text"
                className="form-control"
                id="no-of-nights"
                placeholder="Coupon Code"
                value={formik.values.coupon_code}
                name="coupon_code"
                onChange={(e) => {
                  formik.setFieldValue("coupon_code", e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-lg-6">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                checked={vegPreference}
                value={vegPreference}
                onChange={(e) => {
                  setVegPreference(!vegPreference);
                }}
              />
              <label className="form-check-label" for="inlineCheckbox1">
                Veg
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox2"
                checked={nonVegPreference}
                value={nonVegPreference}
                onChange={(e) => {
                  setNonVegPreference(!nonVegPreference);
                }}
              />
              <label className="form-check-label" for="inlineCheckbox2">
                Non Veg
              </label>
            </div>
          </div>
          <div className="col-12">
            {formik.errors.meal_plan && (
              <div className="error-message">{formik.errors.meal_plan}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label for="specific-request">Specific Request</label>
              <textarea
                className="form-control h-auto"
                id="specific-request"
                name="any_special_request"
                value={formik.values.any_special_request}
                onChange={(e) => {
                  formik.setFieldValue("any_special_request", e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="text-end">
          <button className="btn btn-2" type="submit">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepOne;
