import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { forgotPasswordAPI, login } from "../apis/authAPI";

const initialState = {
  enquires: [],
  enquiry: null,
  busySlots: null,
};

// Async calls.
export const loginSlice = createAsyncThunk("auth/login", async (params) => {
  const response = await login(params);
  return response.data;
});

// Async calls.
export const forgotPasswordSlice = createAsyncThunk(
  "auth/forgotPasswordSlice",
  async (params) => {
    const response = await forgotPasswordAPI(params);
    return response.data;
  }
);

// Account slice for configuring reducers and actions.
export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(createEnquirySlice.fulfilled, (state, action) => {
  //       state.enquiry = action.payload?.data;
  //     })
  //     .addCase(createEnquirySlice.rejected, (state) => {
  //       state.enquiry = null;
  //     })
  //     .addCase(getBustySlots.fulfilled, (state, action) => {
  //       state.enquiry = action.payload?.data;
  //     })
  //     .addCase(getBustySlots.rejected, (state) => {
  //       state.enquiry = null;
  //     });
  // },
});

export default authSlice.reducer;
