import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactStars from "react-rating-stars-component";
import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./ReviewDetails.scss";

import slider1 from "../../assets/img/tour/d-1.png";
import slider2 from "../../assets/img/tour/d-2.png";
import slider3 from "../../assets/img/tour/d-3.png";
import dalLake from "../../assets/dal-lake.jpeg";
import mughalGardens from "../../assets/mughal-gardens.jpeg";
import shalimar from "../../assets/shalimar-bagh.jpeg";
import { listAttractionReviews } from "../../shared/redux/slices/dashboardSlice";
import { useHistory, useParams } from "react-router-dom";
import BlockUi from "react-block-ui";

const ReviewDetails = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [reviews, setReviews] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const bannerSlides = [
    {
      src: dalLake,
      altText: "Dal Lake",
      caption1: "Discover Amazing Places of the world",
      caption2: "Discover Amazing Places of the world",
      url: "#",
    },
    {
      src: mughalGardens,
      altText: "Mughal Gardens",
      caption1: "Discover Amazing Places of the world",
      caption2: "Discover Amazing Places of the world",
      url: "#",
    },
    {
      src: shalimar,
      altText: "Shalimar",
      caption1: "Discover Amazing Places of the world",
      caption2: "Discover Amazing Places of the world",
      url: "#",
    },
  ];

  const bannerSlider = (
    <OwlCarousel
      id="banner-home-slider"
      className="owl-carousel owl-theme"
      loop
      margin={0}
      items={1}
      nav
    >
      {bannerSlides.map((item) => {
        return (
          <div
            className="item slick-slider-home"
            key={item.src}
            style={{ backgroundImage: `url(${item.src})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  {/* <div className="button-common">
                        <a href="/enquiry" className="btn-1">Enquire</a>
                      </div> */}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </OwlCarousel>
  );

  // const images = [
  //   {
  //     src: slider1,
  //   },
  //   {
  //     src: slider2,
  //   },
  //   {
  //     src: slider3,
  //   },
  // ];

  const options = {
    responsiveClass: true,
    loop: false,
    dots: false,
    autoplay: true,
    responsive: {
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  const submitReviewHandler = (e) => {
    e.preventDefault();
    history.push(`/review/${params.attractionId}`);
  };

  const getAttractionReviews = async () => {
    setLoading(true);
    const resp = dispatch(
      listAttractionReviews({
        attraction: params.attractionId,
      })
    );
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp && resp.data) {
          setLoading(false);
          setReviews(resp.data);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setLoading(false);
        setReviews([]);
      });
  };

  useEffect(() => {
    getAttractionReviews();
  }, []);

  return (
    <BlockUi blocking={loading}>
      <section id="tour-packes-deatils">
        {bannerSlider}
        {/* <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="common-banner-text  wow zoomIn" data-wow-duration="2s">
                            <div className="common-heading">
                                <h1>Review Details</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      </section>
      <section id="tour-detailes-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="all-details-tour">
                <div className="all-price justify-content-between align-items-center">
                  <div className="tour-heading-detailse">
                    <h2>{sessionStorage.getItem("attractionName") || ""}</h2>
                  </div>
                  <a
                    href=""
                    className="btn btn-primary"
                    onClick={submitReviewHandler}
                  >
                    Submit Review
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt-3">
              <h5>Our Clients Review</h5>
              <div className="client-revews">
                {reviews?.map((review, index) => {
                  return (
                    <div className="client-info-rev">
                      <div className="clients-desnigation">
                        {review?.first_name || review?.last_name ? (
                          <h5>{`${review?.first_name} ${review?.last_name}`}</h5>
                        ) : (
                          <h5>Anonymous</h5>
                        )}
                        <div className="start-text-details">
                          <div className="start-icon-deta mt-0">
                            <ReactStars
                              count={5}
                              size={24}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                              edit={false}
                              value={review?.rating > 5 ? 5 : review?.rating}
                              className=""
                            />
                          </div>
                        </div>
                        {/* uncomment images for the review */}
                        <div className="det-asor-img mt-2">
                          {review?.images && (
                            <OwlCarousel
                              className="owl-carousel owl-theme"
                              {...options}
                              nav
                            >
                              {review?.images?.map((item) => {
                                return (
                                  <div className="item" key={item}>
                                    <img
                                      src={item}
                                      style={{
                                        height: "100px",
                                        width: "150px",
                                      }}
                                      alt={item.substring(
                                        item.lastIndexOf("/") + 1,
                                        item.indexOf("?") - 1
                                      )}
                                    />
                                  </div>
                                );
                              })}
                            </OwlCarousel>
                          )}
                        </div>
                        <p>{review?.feedback}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </BlockUi>
  );
};
export default ReviewDetails;
