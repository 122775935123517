const FooterBottom = (props) => {
  return (
    <div className="footre-bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-8 col-8 text-center">
            <div className="copy-right-para">
              <p>© {new Date().getFullYear()}. All Rights Reserved</p>
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-4 col-sm-4 col-4">
          <div className="copy-right-icon">
            <a href="#"><i className="fab fa-facebook-f face no-ag"></i></a>
            <a href="#"><i className="fab fa-twitter face"></i></a>
            <a href="#"><i className="fab fa-linkedin-in face"></i></a>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};
export default FooterBottom;
