import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Sidebar from "../sidebar/Sidebar";
import ReactStars from "react-rating-stars-component";

import "./SubmitReview.scss";
import "react-datepicker/dist/react-datepicker.css";

const SubmitReview = (props) => {
  const [visitDate, setVisitDate] = useState(new Date());
  const [rating, setRating] = useState();

  const handleDateChange = (value) => {
    setVisitDate(value);
  };

  const updateRating = (value) => {
    setRating(value);
  };

  return (
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-lg-3">
          <Sidebar />
        </div>
        <div className="col-lg-9">
          <div className="dashboard_common_table">
            <h3>Submit your review</h3>
            <div className="profile_update_form">
              <form action="!#" id="profile_form_area">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="f-name">First name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="f-name"
                        placeholder="Your Name"
                        value="Sherlyn"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="l-name">Last name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="l-name"
                        value="chopra"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="mail-address">Email address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="mail-address"
                        value="sherlyn@domain.com"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="mobile-number">Mobile number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile-number"
                        value="+00 123 456 789"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="destination">Destination</label>
                      <input
                        type="text"
                        className="form-control"
                        id="destination"
                        value=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="visit-date">Date of visit</label>
                      <DatePicker
                        id="visit-date"
                        className="form-control"
                        selected={visitDate}
                        onChange={handleDateChange}
                        dateFormat="P"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label for="feedback">Rating</label>
                      <ReactStars
                        count={5}
                        size={24}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                        edit={true}
                        className=""
                        value={rating}
                        onChange={updateRating}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label for="feedback">Feedback</label>
                      <textarea
                        rows="3"
                        className="form-control h-auto"
                        id="feedback"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label for="photos" className="w-100">
                        Upload photos
                      </label>
                      <input type="file" className="" id="photos" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group change_password_field">
                      <label for="" className="w-100"></label>
                      <button type="button" className="btn btn-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubmitReview;
