import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  busySlots,
  createEnquiry,
  getAllEnquiresByUser,
} from "../apis/enquiryAPI";

const initialState = {
  enquires: [],
  enquiry: null,
  busySlots: null,
};

// Async calls.
export const createEnquirySlice = createAsyncThunk(
  "dashboard/createEnquirySlice",
  async (params) => {
    const response = await createEnquiry(params);
    return response.data;
  }
);

export const getBustySlots = createAsyncThunk(
  "dashboard/getBusySlots",
  async (params) => {
    const response = await busySlots(params);
    return response.data;
  }
);

export const getAllEnquiresOfUser = createAsyncThunk(
  "dashboard/getAllEnquiresOfUser",
  async (params) => {
    const response = await getAllEnquiresByUser(params);
    return response.data;
  }
);

// Account slice for configuring reducers and actions.
export const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(createEnquirySlice.fulfilled, (state, action) => {
  //       state.enquiry = action.payload?.data;
  //     })
  //     .addCase(createEnquirySlice.rejected, (state) => {
  //       state.enquiry = null;
  //     })
  //     .addCase(getBustySlots.fulfilled, (state, action) => {
  //       state.enquiry = action.payload?.data;
  //     })
  //     .addCase(getBustySlots.rejected, (state) => {
  //       state.enquiry = null;
  //     });
  // },
});

export default enquirySlice.reducer;
