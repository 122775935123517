import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

// creating functional component ans getting props from app.js and destucturing them
const StepTwo = ({ nextStep, prevStep, setStepTwoValues, stepTwoValues }) => {
  const phoneRegExp = "^[0-9]{10}$";
  const formik = useFormik({
    initialValues: {
      first_name: stepTwoValues?.first_name || "",
      last_name: stepTwoValues?.last_name || "",
      phone_number: stepTwoValues?.phone_number || "",
      email: stepTwoValues?.email || "",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("Please enter your First Name"),
      last_name: Yup.string().required("Please enter your Last Name"),
      email: Yup.string()
        .required("Please enter your valid Email Address")
        .email("Must be a valid email"),
      phone_number: Yup.string()
        .required("Please enter your valid Mobile Number")
        .matches(phoneRegExp, "Phone number is not valid"),
    }),
    onSubmit: (values) => {
      setStepTwoValues(values);
      // nextStep();
    },
  });

  return (
    <div className="common_author_boxed step-form">
      <div className="common_author_heading">
        <h3 className="text-start">User Details</h3>
      </div>

      <form id="profile_form_area" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label for="f-name">First name</label>
              <span className="error-message">*</span>
              <input
                type="text"
                className="form-control"
                id="f-name"
                placeholder="First Name"
                name="first_name"
                onChange={(e) => {
                  formik.setFieldValue("first_name", e.target.value);
                }}
                value={formik.values.first_name}
                onBlur={formik.handleBlur}
              />
              {formik.errors.first_name && formik.touched.first_name && (
                <div className="error-message">{formik.errors.first_name}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label for="l-name">Last name</label>
              <span className="error-message">*</span>
              <input
                type="text"
                className="form-control"
                id="l-name"
                name="last_name"
                placeholder="Last Name"
                onChange={(e) => {
                  formik.setFieldValue("last_name", e.target.value);
                }}
                value={formik.values.last_name}
                onBlur={formik.handleBlur}
              />
              {formik.errors.last_name && formik.touched.last_name && (
                <div className="error-message">{formik.errors.last_name}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label for="mail-address">Email address</label>
              <span className="error-message">*</span>
              <input
                type="text"
                className="form-control"
                id="mail-address"
                name="email"
                placeholder="Email Address"
                onChange={(e) => {
                  formik.setFieldValue("email", e.target.value?.trim());
                }}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && formik.touched.email && (
                <div className="error-message">{formik.errors.email}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label for="mobil-number">Mobile number</label>
              <span className="error-message">*</span>
              <input
                type="text"
                className="form-control"
                id="mobil-number"
                name="phone_number"
                placeholder="Mobile Number"
                onChange={(e) => {
                  formik.setFieldValue("phone_number", e.target.value?.trim());
                }}
                value={formik.values.phone_number}
                onBlur={formik.handleBlur}
              />
              {formik.errors.phone_number && formik.touched.phone_number && (
                <div className="error-message">
                  {formik.errors.phone_number}
                </div>
              )}
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <button className="btn btn-primary" onClick={prevStep}>
            Previous
          </button>

          <button className="btn btn-2" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepTwo;
