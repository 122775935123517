import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useDropzone } from 'react-dropzone'

import './SubmitTestimonial.scss'
import { createTestimonials } from '../../shared/redux/slices/dashboardSlice'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import BlockUi from 'react-block-ui'

const SubmitTestimonial = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const [videoUp, setVideoUp] = useState(null)
  const [loading, setLoading] = useState(false)

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'video/*': ['.mp4', '.mov', '.webm'],
    },
    onDrop: (acceptedFiles) => {
      setVideoUp(true)
    },
  })

  const files = acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>)

  // Formik
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      mobile_number: '',
      feedback: '',
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('Please enter your First Name'),
      last_name: Yup.string().required('Please enter your Last Name'),
      feedback: Yup.string().required('Please enter your feedback.'),
      email: Yup.string()
        .required('Please enter your valid Email Address')
        .email('Must be a valid email'),
      mobile_number: Yup.string().required('Please enter your valid Mobile Number'),
    }),
    onSubmit: (values) => {
      createTestimonial(values)
    },
  })

  const createTestimonial = async (values) => {
    if (acceptedFiles.length > 0) {
      setLoading(true)
      var bodyFormData = new FormData()
      bodyFormData.append(
        'data',
        JSON.stringify({
          email: values.email,
          description: values.feedback,
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.mobile_number,
        })
      )

      acceptedFiles.map((file) => bodyFormData.append(`video`, file))

      const resp = dispatch(createTestimonials(bodyFormData))

      resp
        .then((resp) => {
          // handle result here
          if (resp && resp.payload) {
            toast.success('Testimonial added successfully.')
            setLoading(false)
            history.push('/testimonials')
          } else {
            setLoading(false)
            toast.error('Failed to add Testimonial.')
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          // handle error here
          setLoading(false)
          toast.error('Failed to add Testimonial.')
        })
    } else {
      setLoading(false)
      setVideoUp(false)
    }
  }

  return (
    <div className="container pt-5 pb-5 review-page">
      <BlockUi blocking={loading}>
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard_common_table">
              <h3>Submit Your Testimonial</h3>
              <div className="profile_update_form">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label htmlFor="first_name">
                          First name <span className="error-message">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="first_name"
                          name="first_name"
                          placeholder="First Name"
                          onChange={(e) => {
                            formik.setFieldValue('first_name', e.target.value)
                          }}
                          value={formik.values.first_name}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.first_name && formik.touched.first_name && (
                          <div className="error-message">
                            {formik.errors.first_name}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="l-name">
                          Last name <span className="error-message">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="last_name"
                          name="last_name"
                          placeholder="Last Name"
                          onChange={(e) => {
                            formik.setFieldValue('last_name', e.target.value)
                          }}
                          value={formik.values.last_name}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.last_name && formik.touched.last_name && (
                          <div className="error-message">
                            {formik.errors.last_name}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label htmlFor="mail-address">
                          Email address <span className="error-message">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                          onChange={(e) => {
                            formik.setFieldValue('email', e.target.value?.trim())
                          }}
                          value={formik.values.email}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <div className="error-message">{formik.errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="mobile-number">
                          Mobile number <span className="error-message">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobile_number"
                          name="mobile_number"
                          placeholder="Mobile Number"
                          onChange={(e) => {
                            formik.setFieldValue(
                              'mobile_number',
                              e.target.value?.trim()
                            )
                          }}
                          value={formik.values.mobile_number}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.mobile_number &&
                          formik.touched.mobile_number && (
                            <div className="error-message">
                              {formik.errors.mobile_number}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label htmlFor="feedback">
                          Feedback <span className="error-message">*</span>
                        </label>
                        <textarea
                          rows="3"
                          className="form-control h-auto"
                          id="feedback"
                          name="feedback"
                          onChange={(e) => {
                            formik.setFieldValue('feedback', e.target.value)
                          }}
                          value={formik.values.feedback}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.feedback && formik.touched.feedback && (
                          <div className="error-message">
                            {formik.errors.feedback}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label htmlFor="photos" className="w-100">
                          Upload Video <span className="error-message">*</span>
                        </label>
                        {/* <input type="file" className="" id="photos" /> */}
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <p className="m-0">
                            Drag 'n' drop video here, or click to select the video
                          </p>
                        </div>
                        {videoUp == false ? (
                          <div className="error-message">Please upload a video</div>
                        ) : null}
                        <aside>
                          <div className="px-0 f-700 py-3">
                            <label>{files}</label>
                          </div>
                        </aside>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group change_password_field">
                        <label htmlFor="" className="w-100"></label>
                        <button type="submit" className="btn btn-2">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </BlockUi>
    </div>
  )
}
export default SubmitTestimonial
