import { useState } from "react";
import {
  Card,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
  CardText,
  Button,
} from "reactstrap";
import "./MyTransactions.scss";
import Sidebar from "../sidebar/Sidebar";

const MyTransactions = (props) => {
  return (
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-lg-3">
          <Sidebar />
        </div>
        <div className="col-lg-9">
          <div className="dashboard_common_table">
            <h3>My Transactions</h3>
            <div className="table-responsive-lg table_common_area">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Booking ID</th>
                    <th>Date</th>
                    <th>Location</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01.</td>
                    <td>#JK589V80</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td>$754.00</td>
                    <td className="complete">Completed</td>
                  </tr>
                  <tr>
                    <td>02.</td>
                    <td>#JK589V80</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td>$754.00</td>
                    <td className="complete">Completed</td>
                  </tr>
                  <tr>
                    <td>03.</td>
                    <td>#JK589V80</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td>$754.00</td>
                    <td className="complete">Completed</td>
                  </tr>
                  <tr>
                    <td>04.</td>
                    <td>#JK589V80</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td>$754.00</td>
                    <td className="complete">Completed</td>
                  </tr>
                  <tr>
                    <td>05.</td>
                    <td>#JK589V80</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td>$754.00</td>
                    <td className="cancele">Canceled</td>
                  </tr>
                  <tr>
                    <td>06.</td>
                    <td>#JK589V80</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td>$754.00</td>
                    <td className="complete">Completed</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyTransactions;
