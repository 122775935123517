import "./ActivateAccount.scss";

const ActivateAccount = (props) => {
  return (
    <div className="col-lg-8 offset-lg-2 pt-5 pb-5">
        <div className="common_author_boxed">
            <div className="common_author_heading">
                <h3>Generate password and activate your account</h3>
            </div>
            <div className="common_author_form">
                <form action="#" id="main_author_form">
                    <div className="form-group">
                        <input type="password" className="form-control" placeholder="Password" />
                    </div>
                    <div className="form-group">
                        <input type="password" className="form-control" placeholder="Re-enter Password" />
                    </div>
                    <div className="common_form_submit">
                        <button className="btn btn-2 btn_theme btn_md">Activate Account</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  );
};
export default ActivateAccount;
