import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./slices/dashboardSlice";
import enquiryReducer from "./slices/enquirySlice";
import authReducer from "./slices/authSlice";

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    enquiry: enquiryReducer,
    auth: authReducer,
  },
});
