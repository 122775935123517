import "./TestimonialComponent.scss";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";

import { listTestimonials } from "../../shared/redux/slices/dashboardSlice";
import { useHistory } from "react-router-dom";

const TestimonialComponent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [testimonials, setTestimonials] = useState([]);

  const slider = (
    <OwlCarousel
      id="banner-home-slider"
      className="owl-carousel owl-theme"
      loop
      margin={0}
      items={1}
      nav
      autoPlay={true}
      autoplaySpeed={30}
      freeDrag={true}
      video={true}
      videoWidth={100}
      videoHeight={100}
      autoWidth={true}
      autoplayTimeout={20}
    >
      {testimonials.map((testimonial, index) => {
        return (
          <div className="item">
            <video
              className="video-style"
              key={index}
              src={testimonial.video_s3_url}
              autoPlay={true}
              muted
              controls
            ></video>
          </div>
        );
      })}
    </OwlCarousel>
  );

  const getTestimonials = async () => {
    // setLoading(true)
    const resp = dispatch(listTestimonials());
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          // setLoading(false)
          setTestimonials(resp);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        // setLoading(false)
        setTestimonials([]);
      });
  };

  const getReviewBy = (testimonial) => {
    if (testimonial.first_name || testimonial.last_name) {
      return `${testimonial.first_name} ${testimonial.last_name}`;
    } else {
      return testimonial.email;
    }
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  return (
    <div className="testimonial-component pt-2 px-2">
      <div className="dashboard_common_table">
        <div className="row">
          <div className=" col-12">
            <h3 className="f-600">
              Our Testimonials
              <button
                className="btn btn-2 pull-right"
                onClick={() => {
                  history.push("/submit-testimonials");
                }}
              >
                Submit Testimonial
              </button>
            </h3>
          </div>
        </div>
        <div className="row mt-3">
          {testimonials.map((testimonial, index) => {
            return (
              <div className="col-4 video-container">
                <div className="card">
                  <div className="card-header card-header-style">{`Review By - ${getReviewBy(
                    testimonial
                  )}`}</div>
                  <div className="card-body card-body-custom">
                    <video
                      key={index}
                      className="video-style"
                      src={testimonial.video_s3_url}
                      muted
                      controls
                    ></video>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TestimonialComponent;
