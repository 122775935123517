// imports
import axios from 'axios'
import * as sessionHandler from './SessionHelper'
import { API_URL, HTTP_API_URL, HTTP_SERVER_API_URL } from './Constants'

/**
 * We are using get for GET method, save for POST method, update for PUT method, remove for DELETE method.
 * As delete is keyword we are using method name as remove.
 */

export const axiosPublic = axios.create({
  baseURL: API_URL,
})

export const axiosPublicWithPort = axios.create({
  baseURL: `${HTTP_API_URL}:8080`,
})

export const axiosPrivate = axios.create({
  baseURL: API_URL,
})

export const axiosServerPublicWithPort = axios.create({
  baseURL: `${HTTP_SERVER_API_URL}:8080`,
})

// Interceptor to to check if token is expired.
// axiosPrivate.interceptors.request.use(
//   async (config) => {
//     let currentDate = new Date();
//     let authTokenExpiry = sessionHandler.getAuthTokenExpiry();
//     let customerId = sessionHandler.getCustomerId();
//     // Checking the token expiry
//     if (currentDate.getTime() < authTokenExpiry) {
//       if (config?.headers) {
//         config.headers["Authorization"] = sessionHandler.getAuthToken();
//         config.headers["clouddefense-customer-id"] = customerId;
//       }
//     } else {
//       // Getting the new access token
//       let response = await axiosPublic.post("user/getTokenFromRefreshToken", {
//         refreshToken: sessionHandler.getRefreshToken(),
//       });
//       if (response && response.data && response.data.data) {
//         sessionHandler.setAuthToken(response?.data?.data?.IdToken);
//         if (config?.headers) {
//           config.headers["Authorization"] = sessionHandler.getAuthToken();
//           config.headers["clouddefense-customer-id"] = customerId;
//         }
//       }
//     }
//     return config;
//   },
//   (error) => {
//     handleError();
//     return Promise.reject(error);
//   }
// );

var handleError = function () {
  localStorage.clear()
  window.location.href = '/'
}
