import { useState } from "react";

import {
  Card,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
  CardText,
  Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import "./Sidebar.scss";
import {
  getEmail,
  getFName,
  removeContext,
} from "../../../shared/SessionHelper";

const Sidebar = (props) => {
  const history = useHistory();
  const onMenuClick = (url) => {
    history.push(url);
  };

  return (
    <div className="dashboard_sidebar">
      <div className="dashboard_sidebar_user">
        <h3>{getFName()}</h3>
        {/* <p>
          <a href="tel:+00-123-456-789">+00 123 456 789</a>
        </p> */}
        <p>
          <a href="mailto:sherlyn@domain.com">{getEmail()}</a>
        </p>
      </div>
      <div className="dashboard_menu_area">
        <ul>
          <li>
            <a onClick={() => onMenuClick("/my-profile")}>
              <i className="fas fa-user-circle"></i>My Profile
            </a>
          </li>
          <li>
            <a onClick={() => onMenuClick("/my-enquiries")}>
              <i className="fas fa-book"></i>My Enquiries
            </a>
          </li>
          <li>
            <a onClick={() => onMenuClick("/my-transactions")}>
              <i className="fas fa-exchange"></i>My Transactions
            </a>
          </li>
          <li>
            <a onClick={() => onMenuClick("/submit-review")}>
              <i className="fas fa-comment"></i>Submit Review
            </a>
          </li>
          <li>
            <a
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                removeContext();
                window.location.href = "/";
              }}
            >
              <i className="fas fa-sign-out-alt"></i>Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
