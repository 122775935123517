import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import ReactStars from "react-rating-stars-component";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";

import "./Review.scss";
import "react-datepicker/dist/react-datepicker.css";
import {
  createReviewForAttraction,
  listAttractionFromLocation,
} from "../../shared/redux/slices/dashboardSlice";
import { useDispatch } from "react-redux";
import Select from "react-select";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";

const Review = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [attractions, setAttractions] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  // Formik
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      date_of_visit: new Date(),
      rating: undefined,
      attraction: "",
      feedback: "",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("Please enter your First Name"),
      last_name: Yup.string().required("Please enter your Last Name"),
      email: Yup.string()
        .required("Please enter your valid Email Address")
        .email("Must be a valid email"),
      mobile_number: Yup.string().required(
        "Please enter your valid Mobile Number"
      ),
      date_of_visit: Yup.string().required("Please enter your Date Of Visit"),
      rating: Yup.string().required("Please add Rating."),
      attraction: Yup.object().required("Please select Attraction"),
      feedback: Yup.string().required("Please enter your Feedback."),
    }),
    onSubmit: (values) => {
      createReview(values);
    },
  });

  const getAttractionFromLocation = async () => {
    const resp = dispatch(listAttractionFromLocation({}));
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp?.data?.length > 0) {
          setAttractions(
            resp.data.map((attraction) => {
              return {
                label: `${attraction?.attraction_location_label}/${attraction?.name}`,
                value: attraction?._id,
                ...attraction,
              };
            })
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setAttractions([]);
      });
  };

  const thumbs = files.map((file) => (
    <div className="thumb" key={file.name}>
      <div className="thumb-inner">
        <img
          className="thumb-img"
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const createReview = async (values) => {
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("attraction", values.attraction.value);
    bodyFormData.append("rating", values.rating);
    bodyFormData.append("feedback", values.feedback);
    bodyFormData.append("first_name", values.first_name);
    bodyFormData.append("last_name", values.last_name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("mobile_number", values.mobile_number);
    bodyFormData.append("date_of_visit", `${values.date_of_visit}`);

    acceptedFiles.map((file, index) =>
      bodyFormData.append(`files${index + 1}`, file)
    );

    const resp = dispatch(createReviewForAttraction(bodyFormData));

    resp
      .then((resp) => {
        // handle result here
        toast.success("Review added successfully.");
        setLoading(false);
        history.push("/");
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setLoading(false);
        toast.error("Failed to add review.");
      });
  };

  useEffect(() => {
    if (attractions?.length > 0 && params.attractionId) {
      formik.setFieldValue(
        "attraction",
        attractions.find(
          (attraction) => attraction.value === params.attractionId
        )
      );
    }
  }, [attractions]);

  useEffect(() => {
    getAttractionFromLocation();
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div className="container pt-5 pb-5 review-page">
      <BlockUi blocking={loading}>
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard_common_table">
              <h3>Submit your review</h3>
              <div className="profile_update_form">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label htmlFor="first_name">First name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="first_name"
                          name="first_name"
                          placeholder="First Name"
                          onChange={(e) => {
                            formik.setFieldValue("first_name", e.target.value);
                          }}
                          value={formik.values.first_name}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.first_name &&
                          formik.touched.first_name && (
                            <div className="error-message">
                              {formik.errors.first_name}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="l-name">Last name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="last_name"
                          name="last_name"
                          placeholder="Last Name"
                          onChange={(e) => {
                            formik.setFieldValue("last_name", e.target.value);
                          }}
                          value={formik.values.last_name}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.last_name &&
                          formik.touched.last_name && (
                            <div className="error-message">
                              {formik.errors.last_name}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label htmlFor="mail-address">Email address</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "email",
                              e.target.value?.trim()
                            );
                          }}
                          value={formik.values.email}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <div className="error-message">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="mobile-number">Mobile number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobile_number"
                          name="mobile_number"
                          placeholder="Mobile Number"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "mobile_number",
                              e.target.value?.trim()
                            );
                          }}
                          value={formik.values.mobile_number}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.mobile_number &&
                          formik.touched.mobile_number && (
                            <div className="error-message">
                              {formik.errors.mobile_number}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label htmlFor="destination">Attraction</label>
                        <Select
                          options={attractions}
                          placeholder="Select Attraction"
                          onChange={(value) =>
                            formik.setFieldValue("attraction", value)
                          }
                          value={formik.values.attraction}
                          className="attraction-select-container"
                          classNamePrefix="attraction-select"
                          name="attraction"
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.attraction &&
                          formik.touched.attraction && (
                            <div className="error-message">
                              {formik.errors.attraction}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="visit-date">Date of visit</label>
                        <div className="date-field">
                          <DatePicker
                            id="visit-date"
                            className="form-control"
                            showIcon
                            selected={formik.values.date_of_visit}
                            onChange={(value) =>
                              formik.setFieldValue("date_of_visit", value)
                            }
                            dateFormat="dd/MM/yyyy"
                            name="date_of_visit"
                            onBlur={formik.handleBlur}
                          />
                          <i className="fa fa-calendar" />
                        </div>
                        {formik.errors.date_of_visit &&
                          formik.touched.date_of_visit && (
                            <div className="error-message">
                              {formik.errors.date_of_visit}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label htmlFor="feedback">Rating</label>
                        <ReactStars
                          count={5}
                          size={24}
                          isHalf={false}
                          emptyIcon={<i className="far fa-star"></i>}
                          halfIcon={<i className="fa fa-star-half-alt"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          activeColor="#ffd700"
                          edit={true}
                          className=""
                          value={formik.values.rating}
                          onChange={(value) =>
                            formik.setFieldValue("rating", value)
                          }
                          name="rating"
                        />
                        {formik.errors.rating && formik.touched.rating && (
                          <div className="error-message">
                            {formik.errors.rating}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label htmlFor="feedback">Feedback</label>
                        <textarea
                          rows="3"
                          className="form-control h-auto"
                          id="feedback"
                          name="feedback"
                          onChange={(e) => {
                            formik.setFieldValue("feedback", e.target.value);
                          }}
                          value={formik.values.feedback}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.feedback && formik.touched.feedback && (
                          <div className="error-message">
                            {formik.errors.feedback}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label htmlFor="photos" className="w-100">
                          Upload photos
                        </label>
                        {/* <input type="file" className="" id="photos" /> */}
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p className="m-0">
                            Drag 'n' drop photos here, or click to select the
                            photos
                          </p>
                        </div>
                        <aside className="thumbs-container">{thumbs}</aside>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group change_password_field">
                        <label htmlFor="" className="w-100"></label>
                        <button type="submit" className="btn btn-2">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </BlockUi>
    </div>
  );
};
export default Review;
