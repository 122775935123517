/**
 * Sets the current session context for the application.
 * @param data
 */
export function setContext(res) {
  const token = res?.token;
  localStorage.setItem("token", token);
  localStorage.setItem("id", res?.id);
  localStorage.setItem("fname", res?.first_name);
  localStorage.setItem("email", res?.email);
}

/**
 * Removes the current session context of the application.
 * @param url
 */
export function removeContext() {
  localStorage.clear();
  sessionStorage.clear();
}

export function getToken() {
  return localStorage.getItem("token") || null;
}

export function getId() {
  return localStorage.getItem("id") || null;
}

export function getFName() {
  return localStorage.getItem("fname") || null;
}

export function getEmail() {
  return localStorage.getItem("email") || null;
}
