import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./DestinationDetails.scss";

import slider1 from "../../assets/img/tour/d-1.png";
import slider2 from "../../assets/img/tour/d-2.png";
import slider3 from "../../assets/img/tour/d-3.png";
import dalLake from "../../assets/dal-lake.jpeg";
import mughalGardens from "../../assets/mughal-gardens.jpeg";
import shalimar from "../../assets/shalimar-bagh.jpeg";

import { getAttractionDetails } from "../../shared/redux/slices/dashboardSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import BlockUi from "react-block-ui";

const DestinationDetails = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [attractionDetails, setAttractionDetails] = useState(null);

  const bannerSlides = [
    {
      src: dalLake,
      altText: "Dal Lake",
      caption1: "Discover Amazing Places of the world",
      caption2: "Discover Amazing Places of the world",
      url: "#",
    },
    {
      src: mughalGardens,
      altText: "Mughal Gardens",
      caption1: "Discover Amazing Places of the world",
      caption2: "Discover Amazing Places of the world",
      url: "#",
    },
    {
      src: shalimar,
      altText: "Shalimar",
      caption1: "Discover Amazing Places of the world",
      caption2: "Discover Amazing Places of the world",
      url: "#",
    },
  ];

  const bannerSlider = (
    <OwlCarousel
      id="banner-home-slider"
      className="owl-carousel owl-theme"
      loop
      margin={0}
      items={1}
      nav
    >
      {attractionDetails?.image?.map((item) => {
        return (
          <div
            className="item slick-slider-home"
            key={item}
            style={{ backgroundImage: `url("${item}")` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12"></div>
              </div>
            </div>
          </div>
        );
      })}
    </OwlCarousel>
  );

  const images = [
    {
      src: slider1,
    },
    {
      src: slider2,
    },
    {
      src: slider3,
    },
  ];

  const reviewClickHandler = () => {
    history.push(`/reviews/${params.id}`);
  };

  const getAttraction = async () => {
    setLoading(true);
    const resp = dispatch(
      getAttractionDetails({
        id: params.id,
      })
    );
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp && resp.data) {
          setLoading(false);
          setAttractionDetails(resp.data[0]);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setLoading(false);
        setAttractionDetails(null);
      });
  };

  const submitReviewHandler = (e) => {
    e.preventDefault();
    history.push(`/review/${params.id}`);
  };

  useEffect(() => {
    getAttraction();
  }, []);

  return (
    <BlockUi blocking={loading}>
      <section id="tour-packes-deatils">
        {attractionDetails ? bannerSlider : null}

        {/* <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="common-banner-text  wow zoomIn" data-wow-duration="2s">
                            <div className="common-heading">
                                <h1>Review Details</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      </section>
      <section id="tour-detailes-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="all-details-tour">
                <div className="all-price">
                  <div className="tour-heading-detailse w-100">
                    <h2 className="d-flex justify-content-between">
                      {attractionDetails?.name}
                      {sessionStorage.setItem(
                        "attractionName",
                        attractionDetails?.name
                      )}
                      <a
                        href=""
                        className="btn btn-primary"
                        onClick={submitReviewHandler}
                      >
                        Submit Review
                      </a>
                    </h2>

                    <div className="start-text-details">
                      <div className="start-icon-deta mt-0">
                        {attractionDetails?.avg_review >= 0 && (
                          <ReactStars
                            count={5}
                            size={24}
                            isHalf={true}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#ffd700"
                            edit={false}
                            value={attractionDetails?.avg_review}
                            className=""
                          />
                        )}
                      </div>
                      <div className="revews">
                        {attractionDetails?.total_review > 0 && (
                          <a
                            className="cursor-pointer"
                            onClick={reviewClickHandler}
                          >
                            <h6 className="mt-2">
                              {attractionDetails?.total_review} Reviews
                            </h6>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt-3">
              <h5>Photos</h5>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="all-details-tour">
                <div className="det-asor-img">
                  <OwlCarousel
                    className="owl-carousel owl-theme"
                    loop
                    margin={0}
                    items={1}
                    autoplay={1}
                  >
                    {attractionDetails?.image?.map((item) => {
                      return (
                        <div className="item" key={item}>
                          <img src={item} />
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
              <div className="rweal-reat">
                <h5>About {attractionDetails?.name}</h5>
                <p>{attractionDetails?.description}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="bookoing-secrty">
                <div className="all-pacj-dfgh">
                  <h6>Activities</h6>
                </div>
                <div className="bookk0-natd-form">
                  <div className="packages-includ">
                    <div className="">
                      <div className="right-includ">
                        <ul className="ps-0">
                          {attractionDetails?.activities?.map((activity) => {
                            return (
                              <li className="text-uppercase">
                                <i className="far fa-check-circle"></i>{" "}
                                {activity}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      {/* <div className="right-includ left-includ">
                        <ul className="ps-0">
                          <li>
                            <i className="fas fa-times-circle"></i> Insurance
                            Service
                          </li>
                          <li>
                            <i className="fas fa-times-circle"></i>Drinks
                          </li>
                          <li>
                            <i className="fas fa-times-circle"></i>Mountaining
                          </li>
                          <li>
                            <i className="fas fa-times-circle"></i> Race or any
                            Sports Event
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bookoing-secrty mt-4">
                <div className="all-pacj-dfgh">
                  <h6>Categories</h6>
                </div>
                <div className="bookk0-natd-form">
                  <div className="packages-includ">
                    <div className="">
                      <div className="right-includ">
                        <ul className="ps-0">
                          {attractionDetails?.category?.map((categoryObj) => {
                            return (
                              <li className="text-uppercase">
                                <i className="far fa-check-circle"></i>{" "}
                                {categoryObj}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      {/* <div className="right-includ left-includ">
                        <ul className="ps-0">
                          <li>
                            <i className="fas fa-times-circle"></i> Insurance
                            Service
                          </li>
                          <li>
                            <i className="fas fa-times-circle"></i>Drinks
                          </li>
                          <li>
                            <i className="fas fa-times-circle"></i>Mountaining
                          </li>
                          <li>
                            <i className="fas fa-times-circle"></i> Race or any
                            Sports Event
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="map-inclid">
                <h5>Destination Location</h5>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"
                  width="600"
                  height="250"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </BlockUi>
  );
};
export default DestinationDetails;
