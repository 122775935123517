import { useEffect, useState } from "react";

import "./MyEnquiries.scss";
import Sidebar from "../sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAllEnquiresOfUser } from "../../../shared/redux/slices/enquirySlice";
import { getId } from "../../../shared/SessionHelper";

const MyEnquiries = (props) => {
  const dispatch = useDispatch();

  const [enquiries, setEnquiries] = useState([]);

  const getEnquires = async () => {
    const resp = dispatch(getAllEnquiresOfUser({ userId: getId() }));
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp && resp.data) {
          setEnquiries(resp?.data || []);
        } else {
          setEnquiries([]);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setEnquiries([]);
      });
  };

  useEffect(() => {
    getEnquires();
  }, []);

  return (
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-lg-3">
          <Sidebar />
        </div>
        <div className="col-lg-9">
          <div className="dashboard_common_table">
            <h3>My Enquiries</h3>
            <div className="table-responsive-lg table_common_area">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Destination</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01.</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td className="complete">Completed</td>
                    <td>
                      <i className="fas fa-eye"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>02.</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td className="complete">Completed</td>
                    <td>
                      <i className="fas fa-eye"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>03.</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td className="complete">Completed</td>
                    <td>
                      <i className="fas fa-eye"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>04.</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td className="complete">Completed</td>
                    <td>
                      <i className="fas fa-eye"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>05.</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td className="cancele">Canceled</td>
                    <td>
                      <i className="fas fa-eye"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>06.</td>
                    <td>23 Jan, 2023</td>
                    <td>Hotel</td>
                    <td className="complete">Completed</td>
                    <td>
                      <i className="fas fa-eye"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyEnquiries;
